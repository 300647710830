@media (max-width: 1024px) {
  .showDesktop {
    display: none;
  }
}

@media (min-width: 1024px) {
  .showMobile {
    display: none;
  }
}

.af-class-heading-9{
  color: white;
}

.af-class-body{
  overflow-x: hidden !important;
}

.w-form-fail {
  display: none !important;
}

.display-error{
  display: block !important;
}

.w-nav-overlay {
  top: 0 !important;
}

.af-class-nav-link .w-container {
  justify-content: center !important;
}

.w-button{
  margin-top: 10px !important;
}