.af-class-div-block-8 {
  display: flex !important;
  padding-left: 2vw !important;
  padding-right: 2vw !important;
  overflow-x: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.af-class-heading-12 {
  padding-left: 2vw !important;
}

.blog-article-description {
  padding: 0px 10px !important;
  margin-bottom: 0px !important;
  text-overflow: ellipsis !important;
  overflow: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  font-size: 1.2em !important;
}

.container::-webkit-scrollbar {
  display: none !important;
}

@media only screen and (max-width: 801px) {
  .blog-article-image {
    min-height: 30% !important;
    min-width: 100% !important;
  }
  .af-class-image-21 {
    margin-top: 0px !important;
  }
  .af-class-image-9 {
    margin-bottom: -155px !important;
  }
  .blog-article-description {
    font-size: 0.9em !important;
  }
  .blog-article-title {
    font-size: 1.3em !important;
  }
  .af-class-heading-12 {
    font-size: 1.8em !important;
  }
}

@media only screen and (min-width: 801px) {
  .blog-article-image {
    min-height: 60% !important;
    min-width: 100% !important;
  }
  .blog-article-description {
    font-size: 1.2em !important;
  }
  .blog-article-title {
    font-size: 2em;
  }
  .af-class-heading-12 {
    font-size: 2.5em !important;
  }
}
