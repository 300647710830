@media only screen and (max-width: 801px) {
  .qr-code {
    max-width: 80%;
    height: auto;
  }
  .wave-image-payment {
    height: 15vh;
    width: 100%;
    bottom: 0px;
    opacity: 0.7;
  }

}

@media only screen and (min-width: 801px) {
  .qr-code {
    max-width: 50%;
    height: auto;
  }
  .wave-image-payment {
    height: 20vh;
    width: 100%;
    bottom: 0px;
    opacity: 0.7;
  }
}

.payment-text{
    margin: 2vh 2vw 3vh 2vw;
    font-size: 20;
}
