.textInput {
  width: 50%;
  height: 5%;
  margin: 10px;
  z-index: 1;
  border-radius: 8px;
}



.description {
  width: 50%;
  text-align: justify;
  text-justify: inter-word;
  z-index: 1;
}

.button{
  z-index: 1;
  margin-top: 1vh;
}
