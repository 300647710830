.hundred-hundred {
  height: 100%;
  width: 100%;
}


@media only screen and (max-width: 801px) {
  .logo-image {
    max-width: 50%;
    height: auto;
    margin: 10vh 5vw 10vh 5vw;
    z-index: 1;
  }
  .wave-image {
    height: 50%;
    position: fixed;
    bottom: 0px;
    opacity: 0.7;
    z-index: 0;
  }
}

@media only screen and (min-width: 801px) {
  .logo-image {
    max-width: 25%;
    height: auto;
    margin: 10vh 5vw 10vh 5vw;
    z-index: 1;
  }
  .wave-image {
    max-width: 100%;
    height: auto;
    position: fixed;
    bottom: 0px;
    opacity: 0.7;
    z-index: 0;
  }
}



.fullscreen {
  height: 100vh;
  width: 100vw;
}

.flex-row-space-evenly-center {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.flex-row-space-around-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.flex-row-space-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-space-around-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
